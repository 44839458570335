// REACT
import React, { Component } from 'react';
import { withRouter } from "react-router-dom";

import NavBar from "../Presentation/Global/NavBar";
import Contact from "../Presentation/Contact/Contact";
import Footer from "../Presentation/Global/Footer";


class ContactScreen extends Component {

    render() {
        return (
            <div className="main" id="main">
                <NavBar />
                <Contact />
                <Footer />
            </div>
        )
    }
}


export default withRouter(ContactScreen);