import React from 'react';

class Price extends React.Component {

    render() {
        return (
            <div className="price" id="price">
                <div className="separator-box">
                    <div className="separator-line">
                    </div>
                </div>
                <div>
                    <h3>Kom igång direkt</h3>
                    <h4>Anslut er till något av följande paket.</h4>
                </div>
                <div className="row">
                    <div className="col-12 col-sm-6 col-md-4 price-div">
                        <div className="price-card">
                            <p className="price-name silver">SILVER</p>
                            <p className="price-desc">Vårt rörliga paket</p>
                            <div className="price-seperator"></div>
                            <p className="price-price">0 kr</p>
                            <p className="price-month">Per månad</p>
                            <div className="price-seperator"></div>
                            <p className="price-price-item">700 kr / Startavgift</p>
                            <p className="price-price-item">50 kr / Fudgad person</p>
                            <div className="price-seperator"></div>
                            <p className="price-item">Support - Epost</p>
                            <p className="price-item">X</p>
                            <p className="price-item">X</p>
                            <p className="price-item">X</p>

                            <div className="price-button justify-content-center">
                                <a href="/order">
                                    <p className="price-button-text">VÄLJ PLAN</p>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-sm-6 col-md-4 price-div">
                        <div className="price-card">
                            <p className="price-name gold">GULD</p>
                            <p className="price-desc">Vårt fasta paket</p>
                            <div className="price-seperator"></div>
                            <p className="price-price">1595 kr</p>
                            <p className="price-month">Per månad</p>
                            <div className="price-seperator"></div>
                            <p className="price-price-item">500 kr / Startavgift</p>
                            <p className="price-price-item">0 kr / Fudgad person</p>
                            <div className="price-seperator"></div>
                            <p className="price-item">Support - Epost</p>
                            <p className="price-item">Statistik - Liten</p>
                            <p className="price-item">1 boost / månad</p>
                            <p className="price-item">X</p>

                            <div className="price-button justify-content-center">
                                <a href="/order">
                                    <p className="price-button-text">VÄLJ PLAN</p>
                                </a>

                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-sm-6 col-md-4 price-div">
                        <div className="price-card">
                            <p className="price-name platinum">DIAMANT</p>
                            <p className="price-desc">Vårt premium paket</p>
                            <div className="price-seperator"></div>
                            <p className="price-price">2395 kr</p>
                            <p className="price-month">Per månad</p>
                            <div className="price-seperator"></div>
                            <p className="price-price-item">0 kr / Startavgift</p>
                            <p className="price-price-item">0 kr / Fudgad person</p>
                            <div className="price-seperator"></div>
                            <p className="price-item">Support - Epost &amp; Telefon</p>
                            <p className="price-item">Statistik - Stor</p>
                            <p className="price-item">3 boost / månad</p>
                            <p className="price-item">Skattjakt</p>

                            <div className="price-button justify-content-center">
                                <a href="/order">
                                    <p className="price-button-text">VÄLJ PLAN</p>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Price;