// REACT
import React, { Component } from 'react';
import { withRouter } from "react-router-dom";

import { Stitch, UserPasswordAuthProviderClient } from 'mongodb-stitch-browser-sdk';

import RegisterUser from "../Presentation/RegisterUser/RegisterUser";

class RegisterUserScreen extends Component {

    register = (email, password) => {
        const emailPassClient = Stitch.defaultAppClient.auth
            .getProviderClient(UserPasswordAuthProviderClient.factory);

        emailPassClient.registerWithEmail(email, password)
            .then(() => {
                console.log("Successfully sent account confirmation email!");
            })
            .catch(err => {
                console.log("Error registering new user:", err);
            });
    }



    render() {
        return (
            <RegisterUser
                register={this.register}>
            </RegisterUser>
        )
    }
}


export default withRouter(RegisterUserScreen);