import React from 'react';

class MissingContent extends React.Component {

    render() {
        return (
            <div className="missing-content">
                <h1 className="missing-content-text">Åh nej, det verkar tyvärr som att denna sida är under konstruktion för tillfället.</h1>
            </div>
        );
    }
}

export default MissingContent;