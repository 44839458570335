import React from 'react';

class Contact extends React.Component {

    render() {
        return (
            <div className="contact-content">
                <h2 className="contact-title-text">Kontakta oss</h2>
                <h4 className="contact-subhead-text">Vi vill vet vad du tycker. Oavsett hur stort eller litet! Hör av dig till oss så att vi tillsammans kan göra Fudget ännu bättre!</h4>
                <h4 className="contact-subhead-text contact-info">Adress: Industrigatan 5, 11246 Stockholm</h4>
                <h4 className="contact-subhead-text contact-info">E-postadress: hello@fudget.se</h4>
                <h4 className="contact-subhead-text contact-info">Telefon: +46 70 348 19 40</h4>

                <div className="row justify-content-center">
                    <div className="col-12 col-sm-6 col-md-6 col-lg-4">
                        <div className="contact-card">
                            <div className="contact-height">
                                <p className="card-title">Frågor och svar</p>
                                <p className="card-text">Har du några frågor? hitta svaret på din fråga i vårt hjälpcenter.</p>
                            </div>

                            <div className="card-seperator"></div>
                            <a href="/missing">
                                <p className="info-link">Sök svar i hjälpen</p>
                            </a>
                        </div>
                    </div>
                    <div className="col-12 col-sm-6 col-md-6 col-lg-4">
                        <div className="contact-card">
                            <div className="contact-height">
                                <p className="card-title">Företagsrelationer</p>
                                <p className="card-text">Är du intresserad av att integrera Fudget till er verksamhet? Kontakta oss så hjälper vi dig!</p>
                            </div>

                            <div className="card-seperator"></div>
                            <a href="mailto:hello@fugdet.se">
                                <p className="info-link">hello@fudget.se</p>
                            </a>
                        </div>
                    </div>
                    <div className="col-12 col-sm-6 col-md-6 col-lg-4" >
                        <div className="contact-card">
                            <div className="contact-height">
                                <p className="card-title">Hör av dig till oss</p>
                                <p className="card-text">Har du feedback eller förslag om hur Fudget kan bli bättre? Hör av dig till oss!</p>
                            </div>
                            <div className="card-seperator"></div>
                            <a href="mailto:hello@fugdet.se">
                                <p className="info-link">hello@fudget.se</p>
                            </a>

                        </div>
                    </div>
                </div>

            </div>
        );
    }
}

export default Contact;