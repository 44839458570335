import React from 'react';

import * as emailjs from 'emailjs-com'

class Contact extends React.Component {

    handleSubmit(e) {
        e.preventDefault()

        let templateParams = {
            from_name: e.target.name.value,
            to_name: 'template_1aX0GF4N',
            email: e.target.email.value,
            phone: e.target.phone.value,
            message_html: e.target.message.value,
        }
        emailjs.send(
            'smtp_server',
            'template_1aX0GF4N',
            templateParams,
            'user_JfIB1aK5diaQdFmuwgoiY'
        )
    }

    render() {
        return (
            <div className="contact" id="contact">
                <div className="separator-box">
                    <div className="separator-line">
                    </div>
                </div>
                <div>
                    <h3>Kontakta oss med era frågor</h3>
                    <h4>Hör av er med alla era frågor och funderingar så är vi glada att få hjälpa er.</h4>
                </div>
                <div className="row justify-content-center">
                    <div className="col-md-12 col-lg-8 col-sm-12">
                        <div className="contact-us">
                            <div className="w-100">
                                <form className="theme-form" onSubmit={this.handleSubmit}>
                                    <div className="form-group">
                                        <div className="row">
                                            <div className="col-sm-12 col-md-6 name-input">
                                                <input type="text" name="name" className="form-control " placeholder="Namn" required="required" />
                                            </div>
                                            <div className="col-sm-12 col-md-6 phone-input">
                                                <input type="text" name="phone" className="form-control" placeholder="Telefon" required="required" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <input type="text" name="email" className="form-control" id="exampleFormControlInput1" placeholder="E-postadress" required="required" />
                                    </div>
                                    <div className="form-group">
                                        <textarea className="form-control" name="message" id="exampleFormControlTextarea1" rows="4" placeholder="Meddelande" required="required"></textarea>
                                    </div>
                                    <div className="form-button contact-button-box">
                                        <button type="submit" className="contact-button">
                                            <p className="contact-button-text">SKICKA</p>
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        );
    }
}

export default Contact;