import React from 'react';

import InfoPic1 from "../../../Assets/Images/MainScreen/InfoPicPendant.jpeg";
import InfoPic2 from "../../../Assets/Images/MainScreen/InfoPicPeople.jpeg";
import InfoPic3 from "../../../Assets/Images/MainScreen/InfoPicFriends.jpeg";

class Info extends React.Component {

    render() {
        return (
            <div className="info" id="info">
                <div className="separator-box">
                    <div className="separator-line">
                    </div>
                </div>
                <div>
                    <h3>Uppvisa en kundupplevelse i världsklass</h3>
                    <h4>Fudget hjälper er att snabbt och smidigt fylla era tomma platser när kundtrycket inte är på topp.</h4>
                </div>
                <div className="row">
                    <div className="col-12 col-md-4 info-div">
                        <div className="info-card">
                            <img className="info-picture" src={InfoPic1} alt="InfoPicture1" />
                            <div className="info-height">
                                <h5>Öka Kundtrycket</h5>
                                <h6>Excklusiv marknadsföring via Fudget och YouTube.</h6>
                                <h6>Nå ut till konsumenterna som inte har bokat.</h6>
                                <h6>Psykologist uppbyggd app.</h6>
                                <h6>6x högre chans att just ni blir valda.</h6>
                            </div>


                            <div className="card-seperator"></div>
                            <a href="/info">
                                <p className="info-link">Läs mer</p>
                            </a>
                        </div>
                    </div>
                    <div className="col-12 col-md-4 info-div">
                        <div className="info-card">
                            <img className="info-picture" src={InfoPic2} alt="InfoPicture2" />
                            <div className="info-height">
                                <h5>Fyll Tomma Platser</h5>
                                <h6>Inget bekymmer vid avbokningar eller dippar.</h6>
                                <h6>Lägg ut tomma platser.</h6>
                                <h6>Dynamiska erbjudanden.</h6>
                                <h6>Snabbt och enkelt.</h6>
                            </div>

                            <div className="card-seperator"></div>
                            <a href="/info">
                                <p className="info-link">Läs mer</p>
                            </a>
                        </div>
                    </div>
                    <div className="col-12 col-md-4 info-div" >
                        <div className="info-card">
                            <img className="info-picture" src={InfoPic3} alt="InfoPicture3" />
                            <div className="info-height">
                                <h5>Ny Tid, Ny Kanal</h5>
                                <h6>Häng med i det höga tempot från konsumenterna.</h6>
                                <h6>Stick ut i branschen.</h6>
                                <h6>Anpassa dig efter den spontana tiden.</h6>
                                <h6>Marknadsföring på helt ny nivå.</h6>
                            </div>

                            <div className="card-seperator"></div>
                            <a href="/info">
                                <p className="info-link">Läs mer</p>
                            </a>

                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Info;