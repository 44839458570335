// REACT
import React, { useState } from 'react';
import { Paper, TextField, Button } from '@material-ui/core/';

export default function RegisterUser(props) {
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")

    return (
        <div>
            <Paper className="container-fluid" id="logInContainer" elevation={6} square>

                <div className="inputWrapper">
                    <TextField
                        id="custom-css-outlined-input1"
                        label="email"
                        onChange={(e) => setEmail(e.target.value)}
                        type="email"
                        margin="normal"
                        variant="outlined"
                    />
                    <br />
                    <TextField
                        id="outlined-password-input"
                        label="password"
                        onChange={(e) => setPassword(e.target.value)}
                        type="password"
                        margin="normal"
                        variant="outlined"
                    />


                    <Button variant="outlined" onClick={() => props.register(email, password)}>Register User</Button>



                </div>
            </Paper>
        </div>
    )
}
