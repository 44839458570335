// REACT
import React, { Component } from 'react';
import { withRouter } from "react-router-dom";

import { Stitch, UserPasswordAuthProviderClient } from 'mongodb-stitch-browser-sdk';

import ResetPassword from "../Presentation/ResetPassword/ResetPassword";

class ResetPasswordScreen extends Component {

    resetPassword = (email) => {
        const emailPassClient = Stitch.defaultAppClient.auth
            .getProviderClient(UserPasswordAuthProviderClient.factory);

        emailPassClient.sendResetPasswordEmail(email).then(() => {
            console.log("Successfully sent password reset email!");
        }).catch(err => {
            console.log("Error sending password reset email:", err);
        });
    }

    render() {
        return (
            <ResetPassword
                resetPassword={this.resetPassword}>
            </ResetPassword>
        )
    }
}


export default withRouter(ResetPasswordScreen);