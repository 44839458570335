// REACT
import React, { Component } from 'react';
import { withRouter } from "react-router-dom";

import { Stitch, UserPasswordAuthProviderClient } from 'mongodb-stitch-browser-sdk';

class EmailConfirmScreen extends Component {


    componentWillMount() {
        const url = window.location.search;
        const params = new URLSearchParams(url);
        const token = params.get('token');
        const tokenId = params.get('tokenId');
        console.log(token)
        console.log(tokenId)

        if (token != null && tokenId != null) {
            // Confirm the user's email/password account
            const emailPassClient = Stitch.defaultAppClient.auth
                .getProviderClient(UserPasswordAuthProviderClient.factory);

            return emailPassClient.confirmUser(token, tokenId);
        }
        else {
            console.log("error confirming adress, token is empty")
        }




    }


    render() {
        return (
            <div>
                <p>Hej</p>
            </div>
        )
    }
}


export default withRouter(EmailConfirmScreen);