// REACT
import React, { Component } from 'react';
import { withRouter } from "react-router-dom";

import NavBar from "../Presentation/MainScreen/NavBar";
import Header from "../Presentation/MainScreen/Header";
import Info from "../Presentation/MainScreen/Info";
import Functions from "../Presentation/MainScreen/Functions";
import Price from "../Presentation/MainScreen/Price";
import Footer from "../Presentation/Global/Footer";
import About from "../Presentation/MainScreen/About";
import Contact from "../Presentation/MainScreen/Contact";

import { Stitch } from 'mongodb-stitch-browser-sdk';
Stitch.initializeDefaultAppClient('fudget-gopcb');

class MainScreen extends Component {

    render() {
        return (
            <div className="main" id="main">
                <NavBar />
                <Header />
                <Info />
                <Functions />
                <Price />
                <About />
                <Contact />

                <Footer />
            </div>
        )
    }
}


export default withRouter(MainScreen);