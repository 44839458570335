// REACT
import React, { Component } from 'react';
import { withRouter } from "react-router-dom";

import { Stitch, UserPasswordCredential } from 'mongodb-stitch-browser-sdk';

import Login from "../Presentation/Login/Login";

class LoginScreen extends Component {

    login = (email, password) => {
        const app = Stitch.defaultAppClient
        const credential = new UserPasswordCredential(email, password)
        app.auth.loginWithCredential(credential)
            // Returns a promise that resolves to the authenticated user
            .then(authedUser => console.log(`successfully logged in with id: ${authedUser.id}`))
            .catch(err => console.error(`login failed with error: ${err}`))
    }

    render() {
        return (
            <Login
                login={this.login}>
            </Login>
        )
    }
}


export default withRouter(LoginScreen);