import React from 'react';
import * as emailjs from 'emailjs-com'

import HeaderPicture from "../../../Assets/Images/MainScreen/Headerx.png";


class Header extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            response: false
        }
    }

    handleSubmit = (e) => {
        e.preventDefault()

        let templateParams = {
            from_name: e.target.email.value,
            to_name: 'template_1aX0GF4N',
            email: e.target.email.value,
            message_html: "Förfrågan, bokning av demo",
        }
        emailjs.send(
            'smtp_server',
            'template_1aX0GF4N',
            templateParams,
            'user_JfIB1aK5diaQdFmuwgoiY'
        )
        this.setState({ response: true })
    }

    render() {

        if (this.state.response === false) {
            var responseText = <div></div>
        } else {
            responseText = <p>Tack för din förfrågan, vi hör av oss så snart som möjligt!</p>
        }

        return (
            <div className="header" id="header">
                <div className="row">
                    <div className="col-12 col-lg-6 header-text">
                        <h1 className="header-text1">Fudget gör det enkelt att fylla era tomma platser</h1>
                        <h4 className="header-text2">Reservationer, statistik och kunderbjudanden. Automatiskt, digitalt och enkelt för både er och era kunder.</h4>
                        <form className="header-form" onSubmit={this.handleSubmit}>
                            <div className="header-form-group row">
                                <input type="email" className="header-input" placeholder="Skriv din e-postadress" name="email" required="required" />
                                <button type="submit" className="header-button"><a className="header-button-text" href="#contact">BOKA DEMO</a></button>
                            </div>
                            {responseText}
                        </form>

                    </div>
                    <div className="col-12 col-lg-6 header-image-div">
                        <img className="header-picture" src={HeaderPicture} alt="HeaderPicture" />
                    </div>
                </div>


            </div>
        );
    }
}

export default Header;