import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";

import pic1 from "../../../Assets/Images/MainScreen/Screens/1.png";
import pic2 from "../../../Assets/Images/MainScreen/Screens/2.png";
import pic3 from "../../../Assets/Images/MainScreen/Screens/3.png";
import pic4 from "../../../Assets/Images/MainScreen/Screens/4.png";
import pic5 from "../../../Assets/Images/MainScreen/Screens/5.png";
import pic6 from "../../../Assets/Images/MainScreen/Screens/6.png";
import pic7 from "../../../Assets/Images/MainScreen/Screens/7.png";
import pic8 from "../../../Assets/Images/MainScreen/Screens/8.png";
import pic9 from "../../../Assets/Images/MainScreen/Screens/9.png";
import iphoneframe from "../../../Assets/Images/MainScreen/Iphonex_frame.png";

class Functions extends React.Component {

    render() {
        return (
            <div className="function" id="functions">
                <div className="row">
                    <div className="col-12 col-lg-6 function-image-div">
                        <img src={iphoneframe} alt="Iphone Frame" className="iphone-frame" />
                        <Carousel width="200px" showThumbs={false} showStatus={false} autoPlay={true} interval={5000} infiniteLoop={true} className="carousel">
                            <img src={pic1} alt="Fudget page 1" />
                            <img src={pic2} alt="Fudget page 2" />
                            <img src={pic3} alt="Fudget page 3" />
                            <img src={pic4} alt="Fudget page 4" />
                            <img src={pic5} alt="Fudget page 5" />
                            <img src={pic6} alt="Fudget page 6" />
                            <img src={pic7} alt="Fudget page 7" />
                            <img src={pic8} alt="Fudget page 8" />
                            <img src={pic9} alt="Fudget page 9" />
                        </Carousel>
                    </div>
                    <div className="col-12 col-lg-6 function-text-div">
                        <h2 className="function-title">Fudgets Funktioner</h2>
                        <div className="row function-text-row">
                            <div className="col-1 check-div">
                                <i className="fa fa-check"></i>
                            </div>
                            <div className="col-11">
                                <p className="function-text">Fånga kunder genom att visa upp er bästa sida</p>
                            </div>
                        </div>

                        <div className="row function-text-row">
                            <div className="col-1 check-div">
                                <i className="fa fa-check"></i>
                            </div>
                            <div className="col-11">
                                <p className="function-text">Smidig reservering av plats för era kunder</p>
                            </div>
                        </div>

                        <div className="row function-text-row">
                            <div className="col-1 check-div">
                                <i className="fa fa-check"></i>
                            </div>
                            <div className="col-11">
                                <p className="function-text">Lägg in användbara filter för att matcha målgrupp</p>
                            </div>
                        </div>

                        <div className="row function-text-row">
                            <div className="col-1 check-div">
                                <i className="fa fa-check"></i>
                            </div>
                            <div className="col-11">
                                <p className="function-text">Skräddarsydda kunderbjudanden när ni har dippar</p>
                            </div>
                        </div>


                    </div>
                </div>
            </div>
        );
    }
}

export default Functions;