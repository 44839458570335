import React from 'react';

import AboutPic from "../../../Assets/Images/MainScreen/Avatar/Jacob.jpg";

class About extends React.Component {

    render() {
        return (
            <div className="about" id="about">
                <div className="row">
                    <div className="col-12 col-lg-6">
                        <img className="about-picture" src={AboutPic} alt="AboutPicture" />
                    </div>
                    <div className="col-12 col-lg-6 ">
                        <h2 className="about-title">Vår Vision</h2>
                        <div className="about-text-row">
                            <p className="about-text">
                                I dagens läge är det allt för svårt att leva spontant.
                                När man väl träffas med vänner är det sällan någon som vet vad man ska göra eller äta.
                                Livet är för kort för att behöva planera varje steg.
                                Därför skapade vi Fudget.
                                Oavsett om det är mat eller nöje, hjälper Fudget er att hitta alla era spontanta kunder som finns i närheten.
                                De starkaste minnena skapas från de mest spontana händelserna!</p>
                            <p className="about-person-name">Jacob Möller </p>
                            <p className="about-person-title">CTO &amp; medgrundare</p>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default About;