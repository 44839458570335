import React from 'react';

import Logo from "../../../Assets/Images/MainScreen/Fudget-logo.svg";

class NavBar extends React.Component {

    componentWillMount() {
        window.addEventListener('scroll', this.handleScroll);
    }
    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    }
    handleScroll = () => {
        if (window.scrollY > 50) {
            document.getElementById("navbar").style.boxShadow = "1px 1px 5px #888888";
        } else {
            document.getElementById("navbar").style.boxShadow = "0px 0px 0px #888888";
        }
    }
    scrollToView = (id) => {
        const yOffset = -80;
        const element = document.getElementById(id);
        const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
        window.scrollTo({ top: y, behavior: 'smooth' });
    }

    toggleHamburger = () => {
        var width = document.getElementById("sidebar").style.width;
        if (width === "230px") {
            document.getElementById("sidebar").style.width = "0px";
            document.getElementById("main").style.transition = "0.5s";
            document.getElementById("main").style.touchAction = "auto";
            document.getElementById("hamburger-button").style.marginRight = "40px";
            document.getElementById("hamburger-button").style.transition = "0.5s";
            document.getElementById("navbar-logo").style.marginLeft = "40px";
            document.getElementById("navbar-logo").style.transition = "0.5s";
            document.getElementById("hamburger").classList.add('fa-bars');
            document.getElementById("hamburger").classList.remove('fa-times');
        } else {
            document.getElementById("sidebar").style.width = "230px";
            document.getElementById("sidebar").style.touchAction = "none";
            document.getElementById("main").style.transition = "0.5s";
            document.getElementById("main").style.touchAction = "none";
            document.getElementById("hamburger-button").style.marginRight = "270px";
            document.getElementById("hamburger-button").style.transition = "0.5s";
            document.getElementById("navbar-logo").style.marginLeft = "-190px";
            document.getElementById("navbar-logo").style.transition = "0.5s";
            document.getElementById("hamburger").classList.remove('fa-bars');
            document.getElementById("hamburger").classList.add('fa-times');
        }
    }
    sidebarPress = (id) => {
        this.toggleHamburger()
        this.scrollToView(id)
    }

    render() {
        return (
            <div>
                <nav className="navbar" id="navbar">
                    <a className="navbar-logo" href="/">
                        <img className="navbar-logo" id="navbar-logo" src={Logo} alt="logo" />
                    </a>
                    <ul className="navbar-list">
                        <li className="navbar-item" onClick={() => this.scrollToView("header")}><p className="n-item">HEM</p></li>
                        <li className="navbar-item" onClick={() => this.scrollToView("info")}><p className="n-item">BESKRIVNING</p></li>
                        <li className="navbar-item" onClick={() => this.scrollToView("functions")}><p className="n-item">FUNKTIONER</p></li>
                        <li className="navbar-item" onClick={() => this.scrollToView("price")}><p className="n-item">PRIS</p></li>
                        <li className="navbar-item" onClick={() => this.scrollToView("about")}><p className="n-item">VISION</p></li>
                        <li className="hamburger-button" id="hamburger-button" onClick={this.toggleHamburger}><i id="hamburger" className="fa fa-bars"></i></li>
                    </ul>
                    <div className="navbar-button" onClick={() => this.scrollToView("contact")}>
                        <p className="navbar-button-text" >KONTAKTA OSS</p>
                    </div>
                </nav>

                <div className="sidebar" id="sidebar">
                    <ul className="sidebar-list">
                        <li className="sidebar-item" onClick={() => this.sidebarPress("header")}><p className="s-item">HEM</p></li>
                        <li className="sidebar-item" onClick={() => this.sidebarPress("info")}><p className="s-item">BESKRIVNING</p></li>
                        <li className="sidebar-item" onClick={() => this.sidebarPress("functions")}><p className="s-item">FUNKTIONER</p></li>
                        <li className="sidebar-item" onClick={() => this.sidebarPress("price")}><p className="s-item">PRIS</p></li>
                        <li className="sidebar-item" onClick={() => this.sidebarPress("about")}><p className="s-item">VISION</p></li>
                    </ul>
                    <div className="sidebar-button" onClick={() => this.sidebarPress("contact")}>
                        <p className="sidebar-button-text">KONTAKTA OSS</p>
                    </div>
                </div>
            </div>
        );
    }
}

export default NavBar;