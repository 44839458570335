import React from 'react';

import Facebook from "../../../Assets/Images/MainScreen/Icons/Facebook.svg";
import Twitter from "../../../Assets/Images/MainScreen/Icons/Twitter.svg";
import Linkedin from "../../../Assets/Images/MainScreen/Icons/Linkedin.svg";

class Footer extends React.Component {

    render() {
        return (
            <div className="footer">
                <div className="row">
                    <div className="col-sm-4 col-6">

                        <ul className="footer-list">
                            <p className="footer-title">Tjänster</p>
                            <li className="footer-item"><a href="/contact">Marknadsföring</a></li>
                            <li className="footer-item"><a href="/contact">Bokningssystem</a></li>
                            <li className="footer-item"><a href="/contact">Skattjakt</a></li>
                            <li className="footer-item"><a href="/contact">Statistik</a></li>
                        </ul>
                    </div>
                    <div className="col-sm-4 col-6">
                        <ul className="footer-list">
                            <p className="footer-title">Användarhjälp</p>
                            <li className="footer-item"><a href="/contact">Mina sidor</a></li>
                            <li className="footer-item"><a href="/contact">Nyheter</a></li>
                            <li className="footer-item"><a href="/contact">Support</a></li>
                            <li className="footer-item"><a href="/contact">FAQ</a></li>
                        </ul>

                    </div>
                    <div className="col-sm-4 col-6">
                        <ul className="footer-list">
                            <p className="footer-title">Om oss</p>
                            <li className="footer-item"><a href="/contact">Vi är Fudget</a></li>
                            <li className="footer-item"><a href="/contact">Jobba hos oss</a></li>
                            <li className="footer-item"><a href="/contact">Kontakta oss</a></li>
                            <li className="footer-item"><a href="/contact">Press och media</a></li>

                        </ul>
                    </div>
                </div>
                <div className="footer-seperator"></div>
                <div className="row justify-content-center">
                    <img className="social-icon" src={Facebook} alt="Facebook" />
                    <img className="social-icon" src={Twitter} alt="Twitter" />
                    <img className="social-icon" src={Linkedin} alt="LinkedIn" />
                </div>


                <div className="row justify-content-center">
                    <a href="/missing">
                        <p className="footer-text">Personuppgiftsbiträdesavtal</p>
                    </a>
                    <p className="footer-sep">|</p>
                    <a href="/missing">
                        <p className="footer-text">Allmänna villkor</p>
                    </a>

                </div>

                <a href="https://www.allabolag.se/5592393028/fudget-ab">
                    <p className="footer-text">© 2020 Fudget AB</p>
                </a>


            </div >
        );
    }
}

export default Footer;