import React from 'react';

import * as emailjs from 'emailjs-com'



class Order extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            response: false
        }
    }

    handleSubmit(e) {
        e.preventDefault()

        let templateParams = {
            company: e.target.company.value,
            org: e.target.companynumber.value,
            name: e.target.name.value,
            email: e.target.email.value,
            phone: e.target.phone.value,
            position: e.target.position.value,
            package: e.target.package.value,

        }
        emailjs.send(
            'smtp_server',
            'website_order_form',
            templateParams,
            'user_JfIB1aK5diaQdFmuwgoiY'
        )
        this.setState({ response: true })
    }

    render() {

        if (this.state.response === false) {
            var responseText = <div></div>
        } else {
            responseText = <p>Tack för din beställning! Vi hör av oss så snart som möjligt för slutförande av din beställning.</p>
        }

        return (
            <div className="order-content">
                <h2 className="order-title-text">Gör en beställning</h2>

                <div className="row justify-content-center">
                    <div className="col-md-12 col-lg-6 col-sm-12">
                        <div className="contact-us">
                            <div className="w-100">
                                <form className="theme-form" onSubmit={this.handleSubmit}>

                                    <div className="form-group">
                                        <p className="sub-header">Företagets uppgifter</p>
                                        <div className="row">
                                            <div className="col-sm-12 col-md-6 name-input">
                                                <input type="text" name="company" className="form-control " placeholder="Företagets namn" required="required" />
                                            </div>
                                            <div className="col-sm-12 col-md-6 phone-input">
                                                <input type="text" name="companynumber" className="form-control" placeholder="Organisationsnummer" required="required" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <p className="sub-header">Kontaktuppgifter</p>
                                        <div className="row">
                                            <div className="col-sm-12 col-md-6 name-input">
                                                <input type="text" name="name" className="form-control " placeholder="Namn" required="required" />
                                            </div>
                                            <div className="col-sm-12 col-md-6 phone-input">
                                                <input type="text" name="phone" className="form-control" placeholder="Telefon" required="required" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <div className="row">
                                            <div className="col-sm-12 col-md-6 name-input">
                                                <input type="text" name="email" className="form-control " placeholder="E-postadress" required="required" />
                                            </div>
                                            <div className="col-sm-12 col-md-6 phone-input">
                                                <input type="text" name="position" className="form-control" placeholder="Befattning" required="required" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <p className="sub-header">Val av paket</p>
                                        <input type="text" name="package" className="form-control" id="exampleFormControlInput1" placeholder="Silver / Guld / Diamant" required="required" />
                                    </div>
                                    <div className="form-button contact-button-box">
                                        <button type="submit" className="contact-button">
                                            <p className="contact-button-text">BESTÄLL</p>
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                        {responseText}
                    </div>

                </div>

            </div>
        );
    }
}

export default Order;