import React from 'react';

import InfoPic1 from "../../../Assets/Images/MainScreen/InfoPic1.jpeg";
import InfoPic2 from "../../../Assets/Images/MainScreen/InfoPic2.jpeg";
import InfoPic3 from "../../../Assets/Images/MainScreen/InfoPic3.jpeg";

class MoreInfo extends React.Component {

    render() {
        return (
            <div>
                <div className="more-info bg-1" id="more-info">
                    <h2 className="more-info-title">Öka kundtrycket</h2>
                    <h4>Fudget hjälper er att snabbt och smidigt fylla era tomma platser när kundtrycket inte är på topp.</h4>
                    <div className="more-info-card">
                        <div className="row">
                            <div className="col-12 col-sm-12 col-md-6 text-col">
                                <p>Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur, from a Lorem Ipsum passage, and going through the cites of the word in classical literature, discovered the undoubtable source. Lorem Ipsum comes from sections 1.10.32 and 1.10.33 of "de Finibus Bonorum et Malorum" (The Extremes of Good and Evil) by Cicero, written in 45 BC. This book is a treatise.</p>
                            </div>
                            <div className="col-12 col-sm-12 col-md-6 picture-col">
                                <img className="more-info-picture" src={InfoPic1} alt="MoreInfoPicture1" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="more-info bg-2" id="more-info">
                    <h2 className="more-info-title">Fyll tomma platser</h2>
                    <h4>Märker ni att ni vid vissa tidpunkter har många lediga platser? Använd Fudget för att fylla platserna.</h4>
                    <div className="more-info-card">
                        <div className="row">
                            <div className="col-12 col-sm-12 col-md-6 text-col">
                                <p>Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur, from a Lorem Ipsum passage, and going through the cites of the word in classical literature, discovered the undoubtable source. Lorem Ipsum comes from sections 1.10.32 and 1.10.33 of "de Finibus Bonorum et Malorum" (The Extremes of Good and Evil) by Cicero, written in 45 BC. This book is a treatise.</p>
                            </div>
                            <div className="col-12 col-sm-12 col-md-6 picture-col">
                                <img className="more-info-picture" src={InfoPic2} alt="MoreInfoPicture2" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="more-info bg-3" id="more-info">
                    <h2 className="more-info-title">Ny tid, ny kanal</h2>
                    <h4>Tekniken går framåt och det gäller att hänga med. Era kunder är online vilket betyder att ni också måste vara.</h4>
                    <div className="more-info-card">
                        <div className="row">
                            <div className="col-12 col-sm-12 col-md-6 text-col">
                                <p>Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur, from a Lorem Ipsum passage, and going through the cites of the word in classical literature, discovered the undoubtable source. Lorem Ipsum comes from sections 1.10.32 and 1.10.33 of "de Finibus Bonorum et Malorum" (The Extremes of Good and Evil) by Cicero, written in 45 BC. This book is a treatise.</p>
                            </div>
                            <div className="col-12 col-sm-12 col-md-6 picture-col">
                                <img className="more-info-picture" src={InfoPic3} alt="MoreInfoPicture3" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default MoreInfo;