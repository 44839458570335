import React, { Component } from 'react';
import { Route } from 'react-router-dom';

import EmailConfirmScreen from "./Components/Container/EmailConfirmScreen";
import RegisterUserScreen from "./Components/Container/RegisterUserScreen";
import ResetPasswordScreen from "./Components/Container/ResetPasswordScreen";
import NewPasswordScreen from "./Components/Container/NewPasswordScreen";
import LoginScreen from "./Components/Container/LoginScreen";

import MainScreen from "./Components/Container/MainScreen";
import MissingContentScreen from "./Components/Container/MissingContentScreen";
import ContactScreen from "./Components/Container/ContactScreen";
import OrderScreen from "./Components/Container/OrderScreen";
import MoreInfoScreen from "./Components/Container/MoreInfoScreen";

import "./Assets/Style/index.css"

class App extends Component {

  render() {
    return (
      <div className="App">
        <header className="App-header">
          <Route exact path="/" render={() => <MainScreen />} />
          <Route exact path="/missing" render={() => <MissingContentScreen />} />
          <Route exact path="/contact" render={() => <ContactScreen />} />
          <Route exact path="/order" render={() => <OrderScreen />} />
          <Route exact path="/info" render={() => <MoreInfoScreen />} />
          <Route exact path="/register" render={() => <RegisterUserScreen />} />
          <Route exact path="/emailconfirmation" render={() => <EmailConfirmScreen />} />
          <Route exact path="/resetpassword" render={() => <ResetPasswordScreen />} />
          <Route exact path="/newpassword" render={() => <NewPasswordScreen />} />
          <Route exact path="/login" render={() => <LoginScreen />} />
        </header>
      </div>
    );
  }
}

export default App;
