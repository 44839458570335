// REACT
import React, { Component } from 'react';
import { withRouter } from "react-router-dom";

import NavBar from "../Presentation/Global/NavBar";
import MoreInfo from "../Presentation/MoreInfo/MoreInfo";
import Footer from "../Presentation/Global/Footer";


class MoreInfoScreen extends Component {

    render() {
        return (
            <div className="main" id="main">
                <NavBar />
                <MoreInfo />
                <Footer />
            </div>
        )
    }
}


export default withRouter(MoreInfoScreen);