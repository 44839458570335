// REACT
import React, { Component } from 'react';
import { withRouter } from "react-router-dom";

import NavBar from "../Presentation/Global/NavBar";
import Order from "../Presentation/Order/Order";
import Footer from "../Presentation/Global/Footer";


class OrderScreen extends Component {

    render() {
        return (
            <div className="main" id="main">
                <NavBar />
                <Order />
                <Footer />
            </div>
        )
    }
}


export default withRouter(OrderScreen);