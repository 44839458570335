// REACT
import React, { useState } from 'react';
import { Paper, TextField, Button } from '@material-ui/core/';

export default function NewPassword(props) {
    const [password, setPassword] = useState("")

    return (
        <div>
            <Paper className="container-fluid" id="logInContainer" elevation={6} square>

                <div className="inputWrapper">
                    <TextField
                        id="outlined-password-input"
                        label="password"
                        onChange={(e) => setPassword(e.target.value)}
                        type="password"
                        margin="normal"
                        variant="outlined"
                    />
                    <Button variant="outlined" onClick={() => props.newPassword(password)}>New Password</Button>
                </div>
            </Paper>
        </div>
    )
}
