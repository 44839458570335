// REACT
import React, { Component } from 'react';
import { withRouter } from "react-router-dom";

import { Stitch, UserPasswordAuthProviderClient } from 'mongodb-stitch-browser-sdk';

import NewPassword from "../Presentation/NewPassword/NewPassword";

class NewPasswordScreen extends Component {

    newPassword = (password) => {
        const url = window.location.search;
        const params = new URLSearchParams(url);

        const token = params.get('token');
        const tokenId = params.get('tokenId');

        // Confirm the user's email/password account
        const emailPassClient = Stitch.defaultAppClient.auth
            .getProviderClient(UserPasswordAuthProviderClient.factory);

        emailPassClient.resetPassword(token, tokenId, password).then(() => {
            console.log("Successfully reset password!");
        }).catch(err => {
            console.log("Error resetting password:", err);
        });
    }

    render() {
        return (
            <NewPassword
                newPassword={this.newPassword}>
            </NewPassword>
        )
    }
}


export default withRouter(NewPasswordScreen);