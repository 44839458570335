// REACT
import React, { Component } from 'react';
import { withRouter } from "react-router-dom";

import NavBar from "../Presentation/Global/NavBar";
import MissingContent from "../Presentation/MissingContent/MissingContent";
import Footer from "../Presentation/Global/Footer";


class MissingContentScreen extends Component {

    render() {
        return (
            <div className="main" id="main">
                <NavBar />
                <MissingContent />
                <Footer />
            </div>
        )
    }
}


export default withRouter(MissingContentScreen);