// REACT
import React, { useState } from 'react';
import { Paper, TextField, Button } from '@material-ui/core/';

export default function ResetPassword(props) {
    const [email, setEmail] = useState("")
    console.log("hej")

    return (
        <div>
            <Paper className="container-fluid" id="logInContainer" elevation={6} square>

                <div className="inputWrapper">
                    <TextField
                        id="custom-css-outlined-input1"
                        label="email"
                        onChange={(e) => setEmail(e.target.value)}
                        type="email"
                        margin="normal"
                        variant="outlined"
                    />
                    <Button variant="outlined" onClick={() => props.resetPassword(email)}>Reset Password</Button>
                </div>
            </Paper>
        </div>
    )
}
